import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import GalleryBgStatic from "../../Assets/Svg/Gallery_Back_Stitc.svg";
import GalleryWaistCoat from "../../Product/School_Uniforms/waistcoat/MainGallery.jpg";
import GalleryVestsSchool from "../../Product/School_Uniforms/Vests/MainGallery.jpg";
import GalleryTrousersShorts from "../../Product/School_Uniforms/Trousers-Shorts/MainGallery.jpg";
import GallerySportsWear from "../../Product/School_Uniforms/Sports-Wear/MainGallery.jpg";
import GallerySkirtsSkorts from "../../Product/School_Uniforms/Skirts-Skorts/MainGallery.jpg";
import GalleryShirtsSchool from "../../Product/School_Uniforms/Shirts/MainGallery.jpg";
import GalleryPolosTShirts from "../../Product/School_Uniforms/Polos_T-Shirts/MainGallery.jpg";
import GalleryPinaFores from "../../Product/School_Uniforms/Pinafores/MainGallery.jpg";
import GalleryJumperSchool from "../../Product/School_Uniforms/Jumper/MainGallery.jpg";
import GalleryGraduationGown from "../../Product/School_Uniforms/Graduation-Gown/MainGallery.jpg";
import GalleryCardigansSchool from "../../Product/School_Uniforms/Cardigans/MainGallery.jpg";
import GalleryBlazersSchool from "../../Product/School_Uniforms/Blazers/MainGallery.jpg";
import GallleryHoodySweatShirt from "../../Product/Casual/Hoody_Sweatshirt/MainGallery.webp";
import GalleryJacketsVestCas from "../../Product/Casual/Jackets_Vest_Cas/MainGallery.webp";
import GalleryPoloCasual from "../../Product/Casual/Polo_Cas/MainGallery.webp";
import GalleryCapCasual from "../../Product/Casual/Cap_Cas/MainGallery.webp";
import GalleryVisibilitySuit from "../../Product/Industrial/Visibility_Suit/MainGallery.webp";
import GalleryVisibilityShirt from "../../Product/Industrial/Visibility_Shirt/MainGallery.webp";
import GalleryVisibilityJacket from "../../Product/Industrial/Visibility_Jacket/MainGallery.webp";
import GalleryVisibilityHiVisBib from "../../Product/Industrial/Visibility_Hi_Vis_Bib/MainGallery.webp";
import GalleryVisibilityCoverall from "../../Product/Industrial/Visibility_Coverall/MainGallery.webp";
import GalleryIndustrialVest from "../../Product/Industrial/Vest/MainGallery.webp";
import GalleryFireRetardantCoverall from "../../Product/Industrial/Fire_Retardant_coverall/MainGallery.webp";
import GalleryCoverall from "../../Product/Industrial/Coverall/MainGallery.webp";
import GalleryAprons from "../../Product/Hospitality/Aprons/MainGallery.webp";
import GalleryChefCoat from "../../Product/Hospitality/Chef_Coat/MainGallery.webp";
import GalleryChefSuit from "../../Product/Hospitality/Chef_Suit/MainGallery.webp";
import GalleryChefHat from "../../Product/Hospitality/Chef_Hat/MainGallery.webp";
import GalleryOutfitSport from "../../Product/Sportswear/Outfit-Sport/MainGallery.webp";
import GalleryTopsBottomsSport from "../../Product/Sportswear/Tops&Bottoms-Sport/MainGallery.webp";
import GallerySuitsVestsCor from "../../Product/Corporate/Suits&Vests-Cor/MainGallery.webp";
import GallerySecurityCor from "../../Product/Corporate/Security-Cor/MainGallery.webp";
import GalleryBlouseFormalCor from "../../Product/Corporate/Blouse&Formal-Shirts-Cor/MainGallery.webp";
import GalleryDressesCor from "../../Product/Corporate/Dresses/MainGallery.webp";
import GallerySkirtsTrousersCor from "../../Product/Corporate/Skirts&Trousers-Cor/MainGallery.webp";

import { ReactComponent as GalleryIconAll } from "../../Assets/Svg/GalleryIconAll.svg";
import { ReactComponent as ScoolUniform } from "../../Assets/Svg/HomeS5P2Img2.svg";
import { ReactComponent as CorporateUniform } from "../../Assets/Svg/HomeS5P2Img9.svg";
import { ReactComponent as IndustrialUniform } from "../../Assets/Svg/HomeS5P2Img6.svg";
import { ReactComponent as HospitalityUniform } from "../../Assets/Svg/HomeS5P2Img13.svg";
import { ReactComponent as MedicalUniform } from "../../Assets/Svg/HomeS5P2Img10.svg";
import { ReactComponent as CasualUniform } from "../../Assets/Svg/HomeS5P2Img11.svg";
import { ReactComponent as SportsUniform } from "../../Assets/Svg/HomeS5P2Img12.svg";
import "./GalleryS3.css";

const uniformCategories = [
  { id: "All", name: "ALL", icon: <GalleryIconAll /> },
  { id: "schooluniform", name: "EDUCATIONAL", icon: <ScoolUniform /> },
  { id: "medical", name: "MEDICAL", icon: <MedicalUniform /> },
  { id: "casual", name: "CASUAL", icon: <CasualUniform /> },
  {
    id: "industrial",
    name: "INDUSTRIAL",
    icon: <IndustrialUniform />,
  },
  {
    id: "hospitality",
    name: "HOSPITALITY ",
    icon: <HospitalityUniform />,
  },
  { id: "sports", name: "SPORTS WEAR ", icon: <SportsUniform /> },
  { id: "corporate", name: "CORPORATE ", icon: <CorporateUniform /> },
];

const uniformItems = {
  schooluniform: [
    {
      id: "WaistCoat School",
      name: "WaistCoat",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryWaistCoat,
      link: "/Waistcoat",
    },
    {
      id: "Vests School",
      name: "Vests",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVestsSchool,
      link: "/Vests-Schools",
    },
    {
      id: "Trousers Shorts School",
      name: "Trousers & Shorts",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryTrousersShorts,
      link: "/Trousers-Shorts",
    },
    {
      id: "Sport Swear School",
      name: "Sport Swear",
      backgroundImage: GalleryBgStatic,
      mainImage: GallerySportsWear,
      link: "/Sports-Wear",
    },
    {
      id: "Skirts Skorts School",
      name: "Skirts & Skorts",
      backgroundImage: GalleryBgStatic,
      mainImage: GallerySkirtsSkorts,
      link: "/Skirts-Skorts",
    },
    {
      id: "Shirts School",
      name: "Shirts",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryShirtsSchool,
      link: "/Shirts-School",
    },
    {
      id: "Polos T-Shirts School",
      name: "Polos & T-Shirts",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryPolosTShirts,
      link: "/Polos-TShirts",
    },
    {
      id: "Pina Fores School",
      name: "Pinafores",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryPinaFores,
      link: "/PinaFores",
    },
    {
      id: "Jumper School",
      name: "Jumper",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryJumperSchool,
      link: "/Jumper-School",
    },
    {
      id: "Graduation Gown School",
      name: "Graduation Gown",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryGraduationGown,
      link: "/Graduation-Gown",
    },
    {
      id: "Cardigans School",
      name: "Cardigans",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryCardigansSchool,
      link: "/Cardigans-School",
    },
    {
      id: "Blazers School",
      name: "Blazers",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryBlazersSchool,
      link: "/Blazers-School",
    },
  ],
  medical: [],
  casual: [
    {
      id: "hoodies casual",
      name: "Hoody Sweat Shirt",
      backgroundImage: GalleryBgStatic,
      mainImage: GallleryHoodySweatShirt,
      link: "/HoodySweatShirt",
    },
    {
      id: "JacketsVestCas",
      name: "Jackets & Vest",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryJacketsVestCas,
      link: "/JacketsVestCas",
    },
    {
      id: "Polo casual",
      name: "Polo",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryPoloCasual,
      link: "/PoloCasual",
    },
    {
      id: "Cap casual",
      name: "Cap",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryCapCasual,
      link: "/CapCasual",
    },
  ],
  industrial: [
    {
      id: "VisibilitySuit industrial",
      name: "Visibility Suit",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilitySuit,
      link: "/VisibilitySuit",
    },
    {
      id: "VisibilityShirt industrial",
      name: "Visibility Shirt",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilityShirt,
      link: "/VisibilityShirt",
    },
    {
      id: "Visibility_Jacket industrial",
      name: "Visibility Jacket",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilityJacket,
      link: "/VisibilityJacket",
    },
    {
      id: "VisibilityHiVisBib industrial",
      name: "Visibility Hi Vis Bib",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilityHiVisBib,
      link: "/VisibilityHiVisBib",
    },
    {
      id: "VisibilityCoverall industrial",
      name: "Visibility Coverall",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryVisibilityCoverall,
      link: "/VisibilityCoverall",
    },
    {
      id: "IndustrialVest industrial",
      name: "Industrial Vest",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryIndustrialVest,
      link: "/IndustrialVest",
    },
    {
      id: "FireRetardantCoverall industrial",
      name: "Fire Retardant Coverall",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryFireRetardantCoverall,
      link: "/FireRetardantCoverall",
    },
    {
      id: "Coverall industrial",
      name: "Coverall",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryCoverall,
      link: "/Coverall",
    },
  ],
  hospitality: [
    {
      id: "aprons hospitality",
      name: "Aprons",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryAprons,
      link: "/Aprons",
    },
    // {
    //   id: "chefCap hospitality",
    //   name: "Chef Cap",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    {
      id: "chefCoat hospitality",
      name: "Chef Coat",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryChefCoat,
      link: "/ChefCoat",
    },
    {
      id: "chefSuit hospitality",
      name: "Chef Suit",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryChefSuit,
      link: "/ChefSuit",
    },
    {
      id: "chefHat hospitality",
      name: "Chef Hat",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryChefHat,
      link: "/ChefHat",
    },
    // {
    //   id: "waiters hospitality",
    //   name: "Waiters",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "housekeeping hospitality",
    //   name: "Housekeeping",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "bellhop hospitality",
    //   name: "Bellhop",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "frontDesk hospitality",
    //   name: "Front Desk",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "salons&Spas hospitality",
    //   name: "Salons & Spas",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
    // {
    //   id: "cabinCrew hospitality",
    //   name: "Cabin Crew",
    //   backgroundImage: GalleryBgStatic,
    //   mainImage: GalleryS3Image1,
    //   link: "/restaurantStaff",
    // },
  ],
  sports: [
    {
      id: "outfit sports",
      name: "Outfit",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryOutfitSport,
      link: "/OutfitSport",
    },
    {
      id: "Tops-Bottoms-Sports",
      name: "Tops & Bottoms",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryTopsBottomsSport,
      link: "/TopsBottomsSport",
    },
  ],
  corporate: [
    {
      id: "Suits & Vests corporate",
      name: "Suits & Vests",
      backgroundImage: GalleryBgStatic,
      mainImage: GallerySuitsVestsCor,
      link: "/SuitsVestsCor",
    },
    {
      id: "Security corporate",
      name: "Security",
      backgroundImage: GalleryBgStatic,
      mainImage: GallerySecurityCor,
      link: "/SecurityCor",
    },
    {
      id: "Blouse & formal corporate",
      name: "Blouse & formal shirts",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryBlouseFormalCor,
      link: "/BlouseFormalCor",
    },
    {
      id: "Dresses corporate",
      name: "Dresses",
      backgroundImage: GalleryBgStatic,
      mainImage: GalleryDressesCor,
      link: "/Dresses",
    },
    {
      id: "Skirts Trousers Cor",
      name: "Skirts & Trousers",
      backgroundImage: GalleryBgStatic,
      mainImage: GallerySkirtsTrousersCor,
      link: "/Skirts-Trousers",
    },
  ],
};

export default function GalleryS3Com() {
  const location = useLocation();

  const defaultCategory = window.innerWidth < 768 ? "schooluniform" : "All";
  const passedCategory = location.state?.selectedCategory || defaultCategory;

  const [selectedCategory, setSelectedCategory] = useState(passedCategory);

  useEffect(() => {
    if (!location.state) {
      setSelectedCategory(defaultCategory);
    }
  }, [location.state, defaultCategory]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const getItemsToDisplay = () => {
    if (selectedCategory === "All") {
      return Object.values(uniformItems).flat();
    } else {
      return uniformItems[selectedCategory] || [];
    }
  };

  const CategoryButton = ({ category, isSelected, onClick }) => (
    <button className={`category-button ${isSelected ? "active" : ""}`} onClick={() => onClick(category.id)}>
      {category.icon && <span className="icon">{category.icon}</span>}
      {category.name}
    </button>
  );

  return (
    <div className="GalleryS3">
      <div className="categories">
        {uniformCategories.map((category) => (
          <CategoryButton key={category.id} category={category} isSelected={selectedCategory === category.id} onClick={handleCategoryClick} />
        ))}
      </div>
      <div className="uniform-items">
        {getItemsToDisplay().map((item) => (
          <Link key={item.id} to={item.link} className="uniform-item-link">
            <div className="uniform-item">
              <div className="uniform-item-background" style={{ backgroundImage: `url(${item.backgroundImage})` }}>
                <div className="uniform-item-name">{item.name}</div>
                <Link to={item.link} className="view-details-button">
                  Click To View
                </Link>
              </div>
              <img
                src={item.mainImage}
                alt={item.name}
                className="uniform-item-main-image"
                loading="lazy"
                onError={(e) => {
                  e.target.src = "path-to-fallback-image.jpg";
                }}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
