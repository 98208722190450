import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import ArrowLeft from "../../../../Assets/Svg/arrow_forward_Gallery.svg";
import ImageSlider from "../../../../Components/Gallery_Images/ImageSlider";
import ThumbnailGallery from "../../../../Components/Gallery_Images/ThumbnailGallery";
import Image1 from "../../../../Product/Industrial/Fire_Retardant_coverall/FRC1.webp";
import Image2 from "../../../../Product/Industrial/Fire_Retardant_coverall/FRC2.webp";
import Image3 from "../../../../Product/Industrial/Fire_Retardant_coverall/FRC3.webp";
import Image4 from "../../../../Product/Industrial/Fire_Retardant_coverall/FRC4.webp";
import "../../Gallery_Product.css";

const FireRetardantCoverall = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const images = [Image1, Image2, Image3, Image4];

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };
  const handleCategoryClick = () => {
    navigate("/gallery", { state: { selectedCategory: "industrial" } });
  };
  return (
    <>
      <div className="Gallery_Product_Container">
        <div className="Gallery_Product_S1">
          <Link to="/gallery">Gallery</Link>
          <img src={ArrowLeft} alt="" />
          <h3 onClick={handleCategoryClick} style={{ cursor: "pointer" }}>
            Industrial
          </h3>
          <img src={ArrowLeft} alt="" />
          <h4>Fire Retardant Coverall</h4>
        </div>
        <div className="Gallery_Product_S2">
          <ImageSlider images={images} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
        </div>
        <div className="Gallery_Product_S3">
          <ThumbnailGallery images={images} onThumbnailClick={handleThumbnailClick} />
        </div>
      </div>
    </>
  );
};

export default FireRetardantCoverall;
