import React from "react";
import "./Media.css";
// import VideoSrc from "../../Assets/Videos/HeroHomeVideo.mp4";
// import MediaPlayB from "../../Assets/Svg/Media/MediaPlayB.svg";
import MediaSliderImg1 from "../../Assets/Webp/Media/MediaSliderImg1.webp";
import MediaSliderImg2 from "../../Assets/Webp/Media/MediaSliderImg2.webp";
import MediaSliderImg3 from "../../Assets/Webp/Media/MediaSliderImg3.webp";
import MediaSliderImg4 from "../../Assets/Webp/Media/MediaSliderImg4.webp";
import MediaSliderImg5 from "../../Assets/Webp/Media/MediaSliderImg5.webp";
import MediaSliderImg6 from "../../Assets/Webp/Media/MediaSliderImg6.webp";
import MediaSliderImg7 from "../../Assets/Webp/Media/MediaSliderImg7.webp";
import MediaSliderImg8 from "../../Assets/Webp/Media/MediaSliderImg8.webp";
import MediaSliderImg9 from "../../Assets/Webp/Media/MediaSliderImg9.webp";
import MediaSliderImg10 from "../../Assets/Webp/Media/MediaSliderImg10.webp";
import MediaSliderImg11 from "../../Assets/Webp/Media/MediaSliderImg11.webp";
import MediaSliderImg12 from "../../Assets/Webp/Media/MediaSliderImg12.webp";
import MediaSliderImg13 from "../../Assets/Webp/Media/MediaSliderImg13.webp";
import MediaSliderImg14 from "../../Assets/Webp/Media/MediaSliderImg14.webp";
import ContactSection from "../../Components/ContactSection/ContactSection";

export default function MediaContent() {
  return (
    <div className="MediaContainer">
      <MediaS1 />
      <MediaS2 />
      <MediaS3 />
    </div>
  );
}

function MediaS1() {
  // const videoRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  // const togglePlayPause = () => {
  //   if (isPlaying) {
  //     videoRef.current.pause();
  //   } else {
  //     videoRef.current.play();
  //   }
  // };

  // useEffect(() => {
  //   const video = videoRef.current;

  //   const handlePlay = () => setIsPlaying(true);
  //   const handlePause = () => setIsPlaying(false);

  //   video.addEventListener("play", handlePlay);
  //   video.addEventListener("pause", handlePause);

  //   return () => {
  //     video.removeEventListener("play", handlePlay);
  //     video.removeEventListener("pause", handlePause);
  //   };
  // }, []);

  return (
    <div className="MediaS1">
      <div className="MediaS1P1">Media</div>
      <div className="MediaS1P2">
        {/* <video ref={videoRef} muted playsInline preload="metadata">
          <source src={VideoSrc} type="video/mp4" />
        </video>
        <div className={`MediaS1P2V ${isPlaying ? "hidden" : ""}`}>
          <button className="play-button" onClick={togglePlayPause} aria-label={isPlaying ? "Pause video" : "Play video"}>
            {isPlaying ? (
              <svg width="50" height="50" viewBox="0 0 24 24">
                <path fill="currentColor" d="M14,19H18V5H14M6,19H10V5H6V19Z" />
              </svg>
            ) : (
              <img src={MediaPlayB} alt="" />
            )}
          </button>
        </div> */}
        <div className="MediaS1P2Video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/3BmEc9_t4eA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="MediaS1P3">
        At Uniform Arena, we create premium uniforms using high-quality fabrics. Our modern factory, equipped with advanced machinery and skilled artisans,
        ensures every piece is crafted with precision and care.
      </div>
      <div className="MediaS1P4"></div>
    </div>
  );
}

function MediaS2() {
  const isMobile = window.innerWidth <= 765;

  return (
    <>
      {isMobile ? (
        <div className="MediaS2">
          <div className="MediaS2P1">
            <img src={MediaSliderImg1} alt="" className="MS2SImg1" />
            <img src={MediaSliderImg2} alt="" className="MS2SImg2" />
          </div>
          {/*  */}
          <div className="MediaS2P1">
            <img src={MediaSliderImg3} alt="" className="MS2SImg1" />
            <img src={MediaSliderImg4} alt="" className="MS2SImg1" />
          </div>
          {/*  */}
          <div className="MediaS2P1">
            <img src={MediaSliderImg5} alt="" className="MS2SImg1" />
            <img src={MediaSliderImg6} alt="" className="MS2SImg1" />
          </div>
          {/*  */}
          <div className="MediaS2P1">
            <img src={MediaSliderImg7} alt="" className="MS2SImg7" />
            <img src={MediaSliderImg8} alt="" className="MS2SImg8" />
          </div>
          {/*  */}
          <div className="MediaS2P1">
            <img src={MediaSliderImg9} alt="" className="MS2SImg9" />
            <img src={MediaSliderImg10} alt="" className="MS2SImg1" />
          </div>
          {/*  */}
          <div className="MediaS2P1">
            <img src={MediaSliderImg11} alt="" className="MS2SImg11" />
            <img src={MediaSliderImg12} alt="" className="MS2SImg1" />
          </div>
          <div className="MediaS2P1">
            <img src={MediaSliderImg13} alt="" className="MS2SImg8" />
            <img src={MediaSliderImg14} alt="" className="MS2SImg14" />
          </div>
        </div>
      ) : (
        <div className="MediaS2">
          <div className="MediaS2P1">
            <img src={MediaSliderImg1} alt="" className="MS2SImg1" />
            <img src={MediaSliderImg2} alt="" className="MS2SImg2" />
            <img src={MediaSliderImg3} alt="" className="MS2SImg1" />
          </div>
          {/*  */}
          <div className="MediaS2P1">
            <img src={MediaSliderImg4} alt="" className="MS2SImg1" />
            <img src={MediaSliderImg5} alt="" className="MS2SImg1" />
            <img src={MediaSliderImg6} alt="" className="MS2SImg1" />
          </div>
          {/*  */}
          <div className="MediaS2P1">
            <img src={MediaSliderImg7} alt="" className="MS2SImg7" />
            <img src={MediaSliderImg8} alt="" className="MS2SImg8" />
            <img src={MediaSliderImg9} alt="" className="MS2SImg9" />
          </div>
          {/*  */}
          <div className="MediaS2P1">
            <img src={MediaSliderImg10} alt="" className="MS2SImg1" />
            <img src={MediaSliderImg11} alt="" className="MS2SImg11" />
            <img src={MediaSliderImg12} alt="" className="MS2SImg1" />
          </div>
          <div className="MediaS2P1">
            <img src={MediaSliderImg13} alt="" className="MS2SImg8" />
            <img src={MediaSliderImg14} alt="" className="MS2SImg14" />
          </div>
        </div>
      )}
    </>
  );
}
function MediaS3() {
  return (
    <>
      <div className="MediaS3">
        <ContactSection />
      </div>
    </>
  );
}
