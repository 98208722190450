import React from "react";
import "./ContactSection.css";
import ButtonContact from "../ButtonContact/ButtonContact";

export default function ContactSection() {
  return (
    <>
      <div className="ContactSection">
        <h3>Have questions or a unique uniform vision?</h3>
        <h1>Email us today to discuss how we can bring it to life!</h1>
        <a href="/contact-us">
          <ButtonContact />
        </a>
      </div>
    </>
  );
}
