import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import ArrowLeft from "../../../../Assets/Svg/arrow_forward_Gallery.svg";
import ImageSlider from "../../../../Components/Gallery_Images/ImageSlider";
import ThumbnailGallery from "../../../../Components/Gallery_Images/ThumbnailGallery";
import Image1 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb1.webp";
import Image2 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb2.webp";
import Image3 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb3.webp";
import Image4 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb4.webp";
import Image5 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb5.webp";
import Image6 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb6.webp";
import Image7 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb7.webp";
import Image8 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb8.webp";
import Image9 from "../../../../Product/Sportswear/Tops&Bottoms-Sport/tb9.webp";

import "../../Gallery_Product.css";

const TopsBottomsSport = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9];

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };
  const handleCategoryClick = () => {
    navigate("/gallery", { state: { selectedCategory: "sports" } });
  };
  return (
    <>
      <div className="Gallery_Product_Container">
        <div className="Gallery_Product_S1">
          <Link to="/gallery">Gallery</Link>
          <img src={ArrowLeft} alt="" />
          <h3 onClick={handleCategoryClick} style={{ cursor: "pointer" }}>
            Sportswear
          </h3>
          <img src={ArrowLeft} alt="" />
          <h4>Tops & Bottoms</h4>
        </div>
        <div className="Gallery_Product_S2">
          <ImageSlider images={images} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
        </div>
        <div className="Gallery_Product_S3">
          <ThumbnailGallery images={images} onThumbnailClick={handleThumbnailClick} />
        </div>
      </div>
    </>
  );
};

export default TopsBottomsSport;
