import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./Assets/Hooks/ScrollUp/ScrollUp";
import EnglishHeader from "./Components/Header/EnglishHeader";
import ArabicHeader from "./Components/Header/ArabicHeader";
import EnglishHeaderMobile from "./Components/Header/EnglishHeaderMobile";
import ArabicHeaderMobile from "./Components/Header/ArabicHeaderMobile";
import EnglishFooter from "./Components/Footer/EnglishFooter";
import ArabicFooter from "./Components/Footer/ArabicFooter";
import EnglishFooterMobile from "./Components/Footer/EnglishFooterMobile";
import ArabicFooterMobile from "./Components/Footer/ArabicFooterMobile";
import Home from "./Pages/Home/Index";
import About from "./Pages/About/Index";
import Gallery from "./Pages/Gallery/Index";
import ContactUs from "./Pages/Contact/Index";
import MediaContent from "./Pages/Media/Media";
import VisibilityJacket from "./Pages/Gallery_Product/Industrial/Visibility_Jacket/Visibility_Jacket";
import Waistcoat from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/waistcoat/waistcoat";
import VestsSchool from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Vests/VestsSchool";
import TrousersShorts from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Trousers-Shorts/TrousersShorts";
import SportsWear from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Sports-Wear/Sports-Wear";
import SkirtsSkorts from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Skirts-Skorts/Skirts-Skorts";
import ShirtsSchool from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Shirts/shirts";
import PolosTShirts from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Polos_T-Shirts/Polos_T-Shirts";
import PinaFores from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/PinaFores/PinaFores";
import JumperSchool from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Jumper/Jumper";
import GraduationGown from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Graduation-Gown/Graduation-Gown";
import Cardigans from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Cardigans/Cardigans";
import BlazersSchool from "./Pages/Gallery_Product/SCHOOL_UNIFORMS/Blazers/Blazers";
import Coverall from "./Pages/Gallery_Product/Industrial/Coverall/Coverall";
import FireRetardantCoverall from "./Pages/Gallery_Product/Industrial/Fire_Retardant_coverall/Fire_Retardant_coverall";
import IndustrialVest from "./Pages/Gallery_Product/Industrial/Vest/Vest";
import VisibilityCoverall from "./Pages/Gallery_Product/Industrial/Visibility_Coverall/Visibility_Coverall";
import VisibilityHiVisBib from "./Pages/Gallery_Product/Industrial/Visibility_Hi_Vis_Bib/Visibility_Hi_Vis_Bib";
import VisibilityShirt from "./Pages/Gallery_Product/Industrial/Visibility_Shirt/Visibility_Shirt";
import VisibilitySuit from "./Pages/Gallery_Product/Industrial/Visibility_Suit/Visibility_Suit";
import Aprons from "./Pages/Gallery_Product/Hospitality/Aprons/Aprons";
import ChefCoat from "./Pages/Gallery_Product/Hospitality/Chef_Coat/Chef_Coat";
import ChefHat from "./Pages/Gallery_Product/Hospitality/Chef_Hat/Chef_Hat";
import ChefSuit from "./Pages/Gallery_Product/Hospitality/Chef_Suit/Chef_Suit";
import HoodySweatShirt from "./Pages/Gallery_Product/Casual/Hoody_Sweatshirt/Hoody_Sweatshirt";
import JacketsVestCas from "./Pages/Gallery_Product/Casual/Jackets_Vest_Cas/Jackets_Vest_Cas";
import PoloCasual from "./Pages/Gallery_Product/Casual/Polo_Cas/Polo_Cas";
import CapCasual from "./Pages/Gallery_Product/Casual/Cap_Cas/Cap_Cas";
import TopsBottomsSport from "./Pages/Gallery_Product/Sportswear/Tops&Bottoms-Sport/Tops&Bottoms-Sport";
import OutfitSport from "./Pages/Gallery_Product/Sportswear/Outfit-Sport/Outfit-Sport";
import SuitsVestsCor from "./Pages/Gallery_Product/Corporate/Suits&Vests-Cor/Suits&Vests-Cor";
import SecurityCor from "./Pages/Gallery_Product/Corporate/Security-Cor/Security-Cor";
import BlouseFormalCor from "./Pages/Gallery_Product/Corporate/Blouse&Formal-Shirts-Cor/Blouse&Formal-Shirts-Cor";
import DressesCor from "./Pages/Gallery_Product/Corporate/Dresses/Dresses";
import SkirtsTrousersCor from "./Pages/Gallery_Product/Corporate/Skirts&Trousers/Skirts&Trousers";
const routes = [
  { path: "/", enComponent: <Home />, arComponent: <Home /> },
  { path: "/about-us", enComponent: <About />, arComponent: <About /> },
  { path: "/gallery", enComponent: <Gallery />, arComponent: <Gallery /> },
  { path: "/contact-us", enComponent: <ContactUs />, arComponent: <ContactUs /> },
  { path: "/media", enComponent: <MediaContent />, arComponent: <MediaContent /> },
  // Industrial Uniforms
  { path: "/Coverall", enComponent: <Coverall />, arComponent: <Coverall /> },
  { path: "/FireRetardantCoverall", enComponent: <FireRetardantCoverall />, arComponent: <FireRetardantCoverall /> },
  { path: "/IndustrialVest", enComponent: <IndustrialVest />, arComponent: <IndustrialVest /> },
  { path: "/VisibilityCoverall", enComponent: <VisibilityCoverall />, arComponent: <VisibilityCoverall /> },
  { path: "/VisibilityHiVisBib", enComponent: <VisibilityHiVisBib />, arComponent: <VisibilityHiVisBib /> },
  { path: "/VisibilityJacket", enComponent: <VisibilityJacket />, arComponent: <VisibilityJacket /> },
  { path: "/VisibilityShirt", enComponent: <VisibilityShirt />, arComponent: <VisibilityShirt /> },
  { path: "/VisibilitySuit", enComponent: <VisibilitySuit />, arComponent: <VisibilitySuit /> },
  // Educational Uniforms
  { path: "/Waistcoat", enComponent: <Waistcoat />, arComponent: <Waistcoat /> },
  { path: "/Vests-Schools", enComponent: <VestsSchool />, arComponent: <VestsSchool /> },
  { path: "/Trousers-Shorts", enComponent: <TrousersShorts />, arComponent: <TrousersShorts /> },
  { path: "/Sports-Wear", enComponent: <SportsWear />, arComponent: <SportsWear /> },
  { path: "/Skirts-Skorts", enComponent: <SkirtsSkorts />, arComponent: <SkirtsSkorts /> },
  { path: "/Shirts-School", enComponent: <ShirtsSchool />, arComponent: <ShirtsSchool /> },
  { path: "/Polos-TShirts", enComponent: <PolosTShirts />, arComponent: <PolosTShirts /> },
  { path: "/PinaFores", enComponent: <PinaFores />, arComponent: <PinaFores /> },
  { path: "/Jumper-School", enComponent: <JumperSchool />, arComponent: <JumperSchool /> },
  { path: "/Graduation-Gown", enComponent: <GraduationGown />, arComponent: <GraduationGown /> },
  { path: "/Cardigans-School", enComponent: <Cardigans />, arComponent: <Cardigans /> },
  { path: "/Blazers-School", enComponent: <BlazersSchool />, arComponent: <BlazersSchool /> },
  // Hospitality Uniforms
  { path: "/Aprons", enComponent: <Aprons />, arComponent: <Aprons /> },
  { path: "/ChefCoat", enComponent: <ChefCoat />, arComponent: <ChefCoat /> },
  { path: "/ChefHat", enComponent: <ChefHat />, arComponent: <ChefHat /> },
  { path: "/ChefSuit", enComponent: <ChefSuit />, arComponent: <ChefSuit /> },
  // Casual Uniforms
  { path: "/HoodySweatShirt", enComponent: <HoodySweatShirt />, arComponent: <HoodySweatShirt /> },
  { path: "/JacketsVestCas", enComponent: <JacketsVestCas />, arComponent: <JacketsVestCas /> },
  { path: "/PoloCasual", enComponent: <PoloCasual />, arComponent: <PoloCasual /> },
  { path: "/CapCasual", enComponent: <CapCasual />, arComponent: <CapCasual /> }, // new
  // Sportswear Uniforms
  { path: "/TopsBottomsSport", enComponent: <TopsBottomsSport />, arComponent: <TopsBottomsSport /> }, // new
  { path: "/OutfitSport", enComponent: <OutfitSport />, arComponent: <OutfitSport /> }, // new
  // Corporate Uniforms
  { path: "/SuitsVestsCor", enComponent: <SuitsVestsCor />, arComponent: <SuitsVestsCor /> }, // new
  { path: "/SecurityCor", enComponent: <SecurityCor />, arComponent: <SecurityCor /> }, // new
  { path: "/BlouseFormalCor", enComponent: <BlouseFormalCor />, arComponent: <BlouseFormalCor /> }, // new
  { path: "/Dresses", enComponent: <DressesCor />, arComponent: <DressesCor /> }, // new
  { path: "/Skirts-Trousers", enComponent: <SkirtsTrousersCor />, arComponent: <SkirtsTrousersCor /> }, // new
];

export default function LanguageRoutes() {
  const location = useLocation();
  const isArabic = location.pathname.endsWith("/ar");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const pageTitles = {
      "/": "Uniform Arena - Home",
      "/about-us": "Uniform Arena - About us",
      "/gallery": "Uniform Arena - Gallery",
      "/media": "Uniform Arena - Media",
    };

    document.title = pageTitles[location.pathname] || "Uniform Arena";
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const HeaderComponent = isSmallScreen ? (isArabic ? ArabicHeaderMobile : EnglishHeaderMobile) : isArabic ? ArabicHeader : EnglishHeader;

  const FooterComponent = isSmallScreen ? (isArabic ? ArabicFooterMobile : EnglishFooterMobile) : isArabic ? ArabicFooter : EnglishFooter;

  return (
    <>
      <HeaderComponent />
      <ScrollToTop />
      <Routes>
        {routes.map(({ path, enComponent, arComponent }, index) => (
          <Route key={index} path={path} element={isArabic ? arComponent : enComponent} />
        ))}
        {routes.map(({ path, arComponent }, index) => (
          <Route key={index} path={`${path}/ar`} element={arComponent} />
        ))}
      </Routes>
      <FooterComponent />
    </>
  );
}
